<template>
  <router-link
    class="px-4 py-1 rounded-sm"
    exact-active-class="bg-blue-500 text-white"
    :to="to"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    to: String,
  },
};
</script>