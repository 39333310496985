<template>
  <div class="w-full min-h-screen flex flex-col justify-between">
    <!-- header -->
    <div>
      <div class="w-full bg-white shadow py-2">
        <header>
          <layout-header />
        </header>
      </div>

      <div class="flex">
        <!-- sidebar -->
        <div class="bg-white shadow">
          <nav>
            <layout-navigation />
          </nav>
        </div>

        <!-- main -->
        <main class="flex-auto">
          <router-view />
        </main>
      </div>
    </div>

    <!-- footer -->
    <footer>
      <div class="w-full">
        <layout-footer />
      </div>
    </footer>
  </div>
</template>

<script>
import layoutHeader from "./header";
import layoutFooter from "./footer";
import layoutNavigation from "./navigation";

export default {
  components: { layoutHeader, layoutNavigation, layoutFooter },
  name: "AdminLayout",
};
</script>